import { makeYearAwareCalendar } from './makeYearAwareCalendar';

export const englishCalendar = makeYearAwareCalendar({
  lastDay: '[yesterday at] LT',
  sameDay: '[today at] LT',
  nextDay: '[tomorrow at] LT',
  lastWeek: 'llll [at] LT',
  nextWeek: 'llll [at] LT',
  sameYear: 'llll [at] LT',
  sameElse: 'll [at] LT',
});
